import React from "react"
import {Container,Navbar,Nav, Row, Col, Form, Button} from 'react-bootstrap';
import NavLink from  "../../components/Custom_Presentation/NavLink"; 
import Header from  "../../components/Common/Header"; 
const PresentationPage = () => {
  
    return <div className="pre-wrapper">
      
      <Header />
    
      <div className="section-wrapper ">
          <Container>
            <h2>Contract Page</h2>
            <p className="fig-content font_20">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec volutpat ipsum vitae magna tempus mollis. Proin sit amet quam a nunc convallis facilisis. </p>
            
            <Form className="contract-form">
                <div className="content-sec contract-sec">
                    <h3>Property Sellers</h3>
                    <Row>
                        <Col md={6}>
                            <h4>Seller #1</h4>

                            <Form.Group>
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" name="full_name" />
                            </Form.Group>

                            <Form.Group controlId="full_name">
                                <Form.Label>Correspondence Address</Form.Label>
                                <Form.Control type="text" name="address" />
                            </Form.Group>

                            <Form.Group controlId="full_name">
                                <Form.Label>Postcode</Form.Label>
                                <Form.Control type="text" name="postcode" />
                            </Form.Group>

                        </Col>
                        <Col md={6}>
                            <h4>Seller #2</h4>

                            <Form.Group>
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" name="full_name" />
                            </Form.Group>

                            <Form.Group controlId="full_name">
                                <Form.Label>Correspondence Address</Form.Label>
                                <Form.Control type="text" name="address" />
                            </Form.Group>

                            <Form.Group controlId="full_name">
                                <Form.Label>Postcode</Form.Label>
                                <Form.Control type="text" name="postcode" />
                            </Form.Group>
                        </Col> 
                    </Row>        
                </div> 

                <div className="content-sec contract-sec">
                    <h3>Legal Representative</h3>

                    <Form.Group>
                        <Form.Label>Company Name and Branch</Form.Label>
                        <Form.Control type="text" name="comp_name_branch" />
                    </Form.Group>

                    <Row>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" name="first_name" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" name="last_name" />
                            </Form.Group>
                        </Col> 

                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Address Line 1</Form.Label>
                                <Form.Control type="text" name="address_line" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Postcode</Form.Label>
                                <Form.Control type="text" name="post_code" />
                            </Form.Group>
                        </Col> 
                    </Row>        
                </div> 

                <div className="content-sec contract-sec">
                    <h3>Step 3 Title</h3>

                    <Row>
                        <Col md={6}>
                            <Button variant="primary" className="mb-4" type="submit">Sole AGency</Button>
                        </Col>
                        <Col md={6}>
                            <Button variant="secondary" className="mb-4" type="submit">multiple agency</Button>
                        </Col> 

                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Period from:</Form.Label>
                                <Form.Control type="date" className="date_fld" name="period_from" />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Period to:</Form.Label>
                                <Form.Control type="date" className="date_fld" name="period_to" />
                            </Form.Group>
                        </Col> 
                    </Row>        
                </div> 

                <div className="content-sec contract-sec">
                    <h3>Tenure</h3>

                    <Row>
                        <Col md={6}>
                            <Button variant="primary" className="mb-4" type="submit">freehold</Button>
                        </Col>
                        <Col md={6}>
                            <Button variant="secondary" className="mb-4" type="submit">leasehold</Button>
                        </Col> 

                        <Col md={6}>
                            <Button variant="secondary" className="mb-4" type="submit">commonheld</Button>
                        </Col>
                        <Col md={6}>
                            <Button variant="secondary" className="mb-4" type="submit">shared ownership</Button>
                        </Col> 
                    </Row>        
                </div> 

                <div className="content-sec contract-sec">
                    <h3>Data Protection</h3>
                    <p className="form_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec volutpat ipsum vitae magna tempus mollis. Proin sit amet quam a nunc convallis facilisis. Vivamus eleifend efficitur lacus quis sagittis. Curabitur nisl dolor, tempor nec eleifend nec, commodo ut nisl. Duis hendrerit lectus malesuada rutrum tincidunt. Mauris et ex magna. Praesent vel pretium tellus.</p>

                    <Row>
                        <Col md={4}>
                            <Button variant="primary" className="mb-4" type="submit">telephone</Button>
                        </Col>
                        <Col md={4}>
                            <Button variant="secondary" className="mb-4" type="submit">email</Button>
                        </Col> 
                        <Col md={4}>
                            <Button variant="secondary" className="mb-4" type="submit">sms</Button>
                        </Col> 
                    </Row>        
                </div>

                <div className="content-sec contract-sec">
                    <h3>Interested Parties</h3>
                    <p className="form_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec volutpat ipsum vitae magna tempus mollis.</p>

                    <Row>
                        <Col md={6}>
                            <Button variant="primary" className="mb-4" type="submit">yes</Button>
                        </Col>
                        <Col md={6}>
                            <Button variant="secondary" className="mb-4" type="submit">no</Button>
                        </Col> 

                        <Col md={12}>
                            <Form.Group>
                                <Form.Label>If yes, provide details:</Form.Label>
                                <Form.Control type="text" name="provide_details" />
                            </Form.Group>
                        </Col>
                    </Row>        
                </div> 

                <div className="content-sec contract-sec">
                    <h3>Financial Evaluation of Prospective Buyers</h3>
                    <p className="form_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec volutpat ipsum vitae magna tempus mollis.</p>

                    <Row>
                        <Col md={6}>
                            <Button variant="primary" className="mb-4" type="submit">yes</Button>
                        </Col>
                        <Col md={6}>
                            <Button variant="secondary" className="mb-4" type="submit">no</Button>
                        </Col> 
                    </Row>        
                </div> 

                <div className="content-sec contract-sec">
                    <h3>EPC (Energy Rating)</h3>
                    <p className="form_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec volutpat ipsum vitae magna tempus mollis.</p>

                    <Row>
                        <Col md={6}>
                            <Button variant="primary" className="mb-4" type="submit">yes</Button>
                        </Col>
                        <Col md={6}>
                            <Button variant="secondary" className="mb-4" type="submit">no</Button>
                        </Col> 
                    </Row>        
                </div> 

                <div className="content-sec contract-sec">
                    <h3>Valuer Signature</h3>
                    <Row>
                        <Col md={12}>
                            <Button variant="primary" className="mb-4" type="submit">add signature</Button>
                        </Col> 
                    </Row>        
                </div>

                <div className="content-sec contract-sec">
                    <h3>Seller Signatuee</h3>

                    <Row className="checkbox_cls">
                        <Col md={12}>
                            <Form.Control type="checkbox" name="seller_signature" />  I confirm I am the owner of the property
                        </Col> 
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Button variant="primary" className="mb-4" type="submit">add signature</Button>
                        </Col> 
                        <Col md={12}>
                            <Button variant="secondary" className="mb-4" type="submit">add second signature</Button>
                        </Col> 
                    </Row>                         
                </div> 

                <div className="content-sec contract-sec">
                    <h3>Right to Cancel</h3>
                    <p className="form_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec volutpat ipsum vitae magna tempus mollis. Proin sit amet quam a nunc convallis facilisis. Vivamus eleifend efficitur lacus quis sagittis. Curabitur nisl dolor, tempor nec eleifend nec, commodo ut nisl. Duis hendrerit lectus malesuada rutrum tincidunt. Mauris et ex magna. Praesent vel pretium tellus. </p>

                    <p className="form_content">Quisque eleifend mattis interdum. Nam porta tellus orci, nec efficitur lorem malesuada ac. Aliquam placerat sit amet odio quis laoreet. Aliquam tincidunt sagittis sem nec cursus. Donec mattis bibendum nisi ac interdum. Aliquam tincidunt molestie enim non consectetur. Nullam vel leo et sapien eleifend dictum. In hac habitasse platea dictumst. Aenean ante ligula, imperdiet sed consequat sed, hendrerit vitae leo.</p>       
                </div>

                <div className="content-sec contract-sec">
                    <h3>Begin immediate marketing of the property?</h3>
                    <Row>
                        <Col md={6}>
                            <Button variant="primary" className="mb-4" type="submit">yes</Button>
                        </Col>
                        <Col md={6}>
                            <Button variant="secondary" className="mb-4" type="submit">no</Button>
                        </Col> 
                    </Row>  
                    <Row className="checkbox_cls mb-1">
                        <Col md={12}>
                            <Form.Control type="checkbox" name="seller_signature" />  I confirm that I am aware that any itemised prepaid fee options are non refundable
                        </Col> 
                    </Row> 
                    <Row className="checkbox_cls">
                        <Col md={12}>
                            <Form.Control type="checkbox" name="seller_signature" />  I confirm I have read the contract in full
                        </Col> 
                    </Row>      
                </div>
                <div className="contract-sec">
                    <Row>
                        <Col md={6}>
                            <Button variant="secondary" className="mb-4" type="submit">preview</Button>
                        </Col> 
                        <Col md={6}>
                            <Button variant="primary" className="mb-4" type="submit">confirm</Button>
                        </Col> 
                    </Row>
                </div>
            </Form>
            
            </Container>
        </div>
      
      <NavLink tab_name="Schedule" />

    </div>
    // pre-wrapper
}

export default PresentationPage
