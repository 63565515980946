import React from "react"
import { Link, navigate } from "gatsby"
import {Container,Navbar,Nav} from 'react-bootstrap';
import {config} from '../Common/constant';

import { useContainerContext } from "../../store/ContainerContext";

function NavLink(props){

    const context = useContainerContext();
    const { property_data } = context.state;

    const navigateLink = (e,link) => {
        //console.log('link---', link)
        navigate(link);
    }

    //console.log('props',props);

    return(
    <>
        <div className="footer-wrapper">
            <Container>
            <div className="footer-wrap">
            <Navbar sticky="bottom" expand="md">
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="hamburger">
                <span className="hambuger-lines"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                <Link to={props.prevLink} className={`left-icon ${props.prevLink ? '' : 'no_link'}`}>  
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1785 0.678711L14.9463 2.44648L7.70517 9.6876L14.9463 16.9287L13.1785 18.6965L4.16964 9.6876L13.1785 0.678711Z" fill="#67707B"/>
                    </svg>
                </Link>
                <Nav>
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, config.custom_present)} className={props.tab_name === "Intro" ? 'actv' : ''} >Intro</Nav.Link>
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, config.custom_launch)} className={props.tab_name === "Launch" ? 'actv' : ''}>Launch</Nav.Link>
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, config.custom_marketing)} className={props.tab_name === "Marketing" ? 'actv' : ''}>Marketing</Nav.Link>
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, config.custom_prop_datetime)} className={props.tab_name === "Schedule" ? 'actv' : ''}>Schedule</Nav.Link>
                    {
                        property_data && parseInt(property_data.price_expectancy) > 200000 && <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, config.custom_plan)} className={props.tab_name === "Plan" ? 'actv' : ''}>Plan</Nav.Link>
                    }
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, config.custom_teams)} className={props.tab_name === "Team" ? 'actv' : ''}>Team</Nav.Link>
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, config.custom_comparables)} className={props.tab_name === "Comparables" ? 'actv' : ''}>Comparables</Nav.Link>
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, config.custom_marketing_campaign)} className={props.tab_name === "Marketing Campaign" ? 'actv' : ''}>Campaign</Nav.Link>
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, config.custom_fees)} className={props.tab_name === "Fees" ? 'actv' : ''}>Fees</Nav.Link>
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, config.custom_contract_form)} className={props.tab_name === "Contract Form" ? 'actv' : ''}>Contract Page</Nav.Link>               
                </Nav>
                <Link to={props.nextLink} className={`right-icon ${props.nextLink ? '' : 'no_link'}`} onClick={props.handClick}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.82148 18.6963L5.05371 16.9285L12.2948 9.68741L5.05371 2.44629L6.82148 0.678522L15.8304 9.68741L6.82148 18.6963Z" fill="#67707B"/>
                    </svg>
                </Link>
                </Navbar.Collapse>
            </Navbar>
            </div>
            {/* footer-wrap */}         
            </Container>
        </div>
        {/* footer-wrapper */}
    </>
    )
}

export default NavLink
